import React from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import { Fade, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Divider from "../components/Divider"
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import squareTiles from "../assets/homePage/homepage1.png";
import connectImg from "../assets/homePage/homefooter.png";
import mainButton from "../assets/homePage/main_button.png";
import roundSphere from "../assets/homePage/homepage2.png";

import ratingImg from "../assets/whyKlippit/rating.png";
import insightImg from "../assets/whyKlippit/insight.png";
import easyUseImg from "../assets/whyKlippit/easy-to-use.png";
import paymentImg from "../assets/whyKlippit/payment.png";

import equalLogo from "../assets/equal.png";
import HIW from "../assets/HIW.mp4";

import Typography from "@material-ui/core/Typography";

import { useHistory } from "react-router-dom";


const GetStartedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  //padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  width: "250px",
  height: "50px",
  backgroundColor: '#108DAA',
  borderColor: '#108DAA',
  color: '#fff',
  borderRadius: "30px",
  cursor: "pointer",
  whiteSpace:"nowrap",
  
 fontFamily: "Lato-Bold",
  '&:hover': {
    borderColor: '#108DAA',
    backgroundColor: '#108DAA',
    boxShadow: 'none',
  },
  
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #108DAA',
    
  },
});
const useStyles = makeStyles((theme) => ({
  pageStyle:{
/*
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),*/
    paddingLeft:"180px",
    paddingRight:"180px",
    [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
      paddingRight: "0px",
    }
  },
  container: {
    paddingTop: "60px",
    paddingBottom: "106px",
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  footerContainer: {
   // paddingTop: "60px",
   // paddingBottom: "106px",
    backgroundColor: "#8ac5cc",
    height:"550px",
color:"#fff",
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      height:"100%",
    },
  },
  banner: {
    paddingRight:"100px",
    paddingLeft:"100px",
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', 
    backgroundColor: '#108DAA',
    color:"#fff",
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      height:"100%",
    },
  },
  heading: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
    paddingRight: "20px",
    fontSize: "50px",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      textAlign: "center",
    },
  },
  headerFooter: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
   // paddingRight: "20px",
    fontSize: "24px",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontFamily:"Lato",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      width:"100%",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width:"100%",
      textAlign: "center",
    },
  },
  subHeading: {
   // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   width:"80%",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      //textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      textAlign: "center",
      width:"100%",
    },
  },
  subHeaderFooter: { 
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontSize: "18px",
    fontFamily:"Lato",
     [theme.breakpoints.down("md")]: {
       fontSize: "20px",
       //textAlign: "center",
     },
     [theme.breakpoints.down("sm")]: {
       fontSize: "15px",
       textAlign: "center",
       width:"100%",
     },
   },
   
  rightTextContainer:{
   paddingLeft:"60px", 
   paddingRight:"60px",
   [theme.breakpoints.down("sm")]: {
    paddingLeft:"0px",
    paddingRight:"0px"
   },
  },
 newsSection:{
  paddingLeft: "128px", 
  paddingRight: "128px",

  [theme.breakpoints.down("md")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
 },
  
  iPhone: {
    width: "100%",
  },
  imageTiles: {
    width: "100%",
 
  },

  mainLeftContainer: {
   //paddingLeft: "4em",
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  mainLeftContainerFooter: {
    color:"#fff",
   height:"100%",
    paddingLeft: "20em",
     paddingRight: "6em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
     [theme.breakpoints.down("sm")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
   },
  mainRightContainer: {
   paddingLeft: "2em",
    //paddingRight: "4em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1.5em",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  mainRightContainerFooter: {
    paddingLeft: "2em",
    height:"100%",
     //paddingRight: "4em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em",
      // paddingRight: "1.5em",
     },
     [theme.breakpoints.down("sm")]: {
       paddingTop: "1.5em",
       paddingLeft: "1.5em",
     paddingRight: "1.5em",
     alignItems:"center"
     },
   },
  testimonalContainer: {
    backgroundColor: "#8AC5CC",
    paddingTop: "66px",
    paddingLeft: "140px",
    paddingRight: "140px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },

}));


const WhyKlippit = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  return (
   <> <Grid container alignItems={"center"} direction={"column"} className={classes.pageStyle}>
      {/*PHOTO TILES*/}
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}

      >
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainLeftContainer}
        >
          <Grid
            item
            container
            alignItems={matchesSM ? null : "flex-end"}
            direction={"column"}
          >
            <Grid item>
              <Grid
                item
                container
                direction={"column"}
                alignItems={matchesSM ? "center" : null}
              >
                <Grid item>
                  <Typography variant={"h1"} className={classes.heading}>
                    Influencer Marketing Made Easy with Klippit
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: "42px" }}>
                  <Typography variant={"h6"} className={classes.subHeading}>
                    Embrace the new era of marketing and revolutionize the way you connect with your audience.
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "42px" }}>
                  <GetStartedButton variant="contained"
                    style={{ top: 0 }}
                    onClick={() => history.push("/login")}>
                    Get Started
                  </GetStartedButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainRightContainer}
          style={{ paddingTop: "40px" }}
        >


<iframe height='100%' width="100%" className={classes.imageTiles}  style={{aspectRatio: '2/1'}} src="https://www.youtube.com/embed/pWYDHmcOqY8?autoplay=1" title="How Klippit works!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </Grid>
      </Grid>




      <br />
      <br />

      {/*Info Section*/}
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}
      >
        <Grid
          item

          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainLeftContainer}
          style={{paddingLeft:"0px"}}
        >
            <Grid item>
              <Grid item container alignItems={matchesSM ? "center" : null} direction={"column"}>

                            <div style={{ position: 'relative', width: '100%', aspectRatio: '1/1', borderRadius: '50%', backgroundColor: '#ffc82c' }}>

                            
                        <CardContent style={{ position: 'absolute', top: '45%', left: '10%', right: '10%', transform: 'translate(0%,-15%)',  color: '#108DAA', textAlign: 'center', fontWeight: '700', fontSize: '30px', fontSize: 'calc(100% + 1.5vw)' }}>
                            Revolutionize your Marketing with Klippit
                        </CardContent>
                        </div>

               </Grid>

        



       </Grid>
      </Grid>



        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainRightContainer}

        >
          <Grid
            item
            container
            alignItems={matchesSM ? null : ""}
            direction={"column"}
            className={classes.rightTextContainer}
            style={{  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', height: '100%'}}
          >
            <Grid item>
              <Grid item container alignItems={matchesSM ? "center" : null} direction={"column"}>

                <Grid item style={{ marginTop: "32px", marginBottom: "32px" }}>
                    <div style={{ display: 'flex' , flexDirection: 'column', width: '100%'  }}>
                        <div style={{ display: 'flex' , flexDirection: 'row', width: '100%', padding: '16px 0px' }}>
                            <div style={{ flex: '3' }}>
                  <Typography variant={"h6"} className={classes.heading} style={{fontFamily:"DroidSerif-Bold", fontSize: "15px", textAlign: 'start', color: '#108DAA' }}>
                    Cost-Effective
                  </Typography>
                                <br />
                  <Typography variant={"body2"} className={classes.heading} style={{fontFamily:"DroidSerif", fontSize: "14px", textAlign: 'start' }}>
                    Enjoy a cost-effective alternative to traditional influencer marketing campaigns.
                  </Typography>
                            </div>
                            <div style={{ flex: '1' }}>
                                <img src={paymentImg} alt="payment image" style={{objectFit: 'contain', width: '75%'}} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' , flexDirection: 'row', width: '100%', padding: '16px 0px' }}>
                            <div style={{ flex: '3' }}>
                  <Typography variant={"h6"} className={classes.heading} style={{fontFamily:"DroidSerif-Bold", fontSize: "15px", textAlign: 'start', color: '#108DAA' }}>
                        Simple Setup
                  </Typography>
                                <br />
                  <Typography variant={"body2"} className={classes.heading} style={{fontFamily:"DroidSerif", fontSize: "14px", textAlign: 'start' }}>
                        Get started with Klippit in just 4 easy steps.
                  </Typography>
                            </div>
                            <div style={{ flex: '1' }}>
                                <img src={easyUseImg} alt="easy to use image" style={{objectFit: 'contain', width: '75%'}} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' , flexDirection: 'row', width: '100%', padding: '16px 0px' }}>
                            <div style={{ flex: '3' }}>
                   <Typography variant={"h6"} className={classes.heading} style={{fontFamily:"DroidSerif-Bold", fontSize: "15px", textAlign: 'start', color: '#108DAA' }}>
                        More Customers
                  </Typography>
                                <br />
                  <Typography variant={"body2"} className={classes.heading} style={{fontFamily:"DroidSerif", fontSize: "14px", textAlign: 'start' }}>
                    Expand your reach and attract new customers through user-generated content.
                  </Typography>
                      
                            </div>
                            <div style={{ flex: '1' }}>
                                <img src={ratingImg} alt="rating image" style={{objectFit: 'contain', width: '75%'}} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' , flexDirection: 'row', width: '100%', padding: '16px 0px' }}>
                            <div style={{ flex: '3' }}>
                   <Typography variant={"h6"} className={classes.heading} style={{fontFamily:"DroidSerif-Bold", fontSize: "15px", textAlign: 'start', color: '#108DAA' }}>
                        Insight
                  </Typography>
                                <br />
                  <Typography variant={"body2"} className={classes.heading} style={{fontFamily:"DroidSerif", fontSize: "14px", textAlign: 'start' }}>
                    Access valuable and insights to inform your marketing and sales strategies.
                  </Typography>
                      
                            </div>
                            <div style={{ flex: '1' }}>
                                <img src={insightImg} alt="insight image" style={{objectFit: 'contain', width: '75%'}} />
                            </div>
                        </div>

                    </div>
               </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*End of Info Section*/}

      <br />
      <br />

     <Grid
     item
     container
     direction={matchesSM ? "column" : "row"}
     className={classes.banner}
     
     >

         <Grid
            item
            container
            alignItems={matchesSM ? null : "center"}
            direction={"column"}
            className={classes.rightTextContainer}
            
          >
            <Grid item>
              <Grid item container alignItems={matchesSM ? "center" : null} direction={"column"}>
                <Grid item style={{ marginTop: "16px", marginBottom: "16px" }}>
                  <Typography variant={"h6"} className={classes.heading} style={{fontFamily:"DroidSerif-Bold", fontSize: "21px", textAlign: 'center', color: '#fff' }}>
                    Did you know...
                </Typography>
                <br />
                <br />
                <Typography variant={"body2"} className={classes.heading} style={{fontFamily:"DroidSerif", fontSize: "17px", textAlign: 'center' }}>
                    Did you know, companies are earning $5.78 for each dollar they spend on influencer marketing?
                </Typography>

               </Grid>

              </Grid>
            </Grid>
          </Grid>

      
    </Grid>
  </Grid>

      <br /><br />
      <br /><br />




     {/*CONNECT*/}
     <Grid
     item
     container
     direction={matchesSM ? "column" : "row"}
     className={classes.footerContainer}
     
     >
    


     <Grid
       item
       lg={7}
       md={7}
       sm={12}
       xs={12}
       className={classes.mainLeftContainerFooter}
       
     >
       <Grid
         item
         container
         alignItems={matchesSM ? null : "flex-end"}
         
         direction={"column"}
       
       >
         <Grid item>
           <Grid item container alignItems={matchesSM ? "center" : null} direction={"column"} style={{color:"#fff"}}>
             
             <Grid item style={{ marginTop: "100px" }}>
               <Typography variant={"h4"} className={classes.headerFooter} >
               Go Beyond Deals. Become a Destination.

               </Typography>
             </Grid>

             <Grid item style={{ marginTop: "26px" }}>
               <Typography variant={"h6"}  className={classes.subHeaderFooter}>
               Klippit Merchant is your platform for becoming someone’s favorite place. Let’s work together to make you a destination.
               </Typography>
             </Grid>

             <Grid item style={{ marginTop: "50px" }}>
             <GetStartedButton variant="contained" 
         style={{top: 0, backgroundColor: "#fff", color:"#108DAA", fontWeight:600, borderColor:"#fff", width:"160px"}}
         onClick={() => history.push("/login")} > 
             Get Started
     </GetStartedButton>
     </Grid>

           </Grid>
         </Grid>
       </Grid>
       </Grid>
       <Grid
       item
      
       lg={5}
       md={5}
       sm={12}
       xs={12}
       className={classes.mainRightContainerFooter}
     >
       <img
         src={connectImg}
         alt="Connect Image"
         style={{height: "100%"}}
         className={classes.imageTiles}
       />
       
     </Grid>
      
    
   </Grid></>
  );
};

export default WhyKlippit;
