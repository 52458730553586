import React, { Fragment, useState } from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Grid from "@material-ui/core/Grid";

import { useHistory } from "react-router-dom";

// NAV IMAGES
import klippitLogo from "../assets/logo/klippitLogo.png";
import whyKlippit from "../assets/nav/whyKlippit.png";
import demo from "../assets/nav/demo.png";
import press from "../assets/nav/press.png";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const GetStartedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  //padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#fff',
  borderColor: '#fff',
  color: '#1190ad',
  borderRadius: "20px",
  fontFamily: "Lato-Bold",
  /*
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),*/
  '&:hover': {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    borderColor: '#1190ad',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const useStyles = makeStyles((theme) => ({
  navBar: {
    backgroundColor: theme.palette.primary.main,
    height: "80px",
    paddingLeft: "100px",
    paddingRight: "180px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
  },

  navLogo: {
    marginRight: "10px",
    cursor: "pointer",
  },
  navLink: {
    marginLeft: "60px",
    alignContent:"center",
    cursor: "pointer",
  },
  navLinkText: {
    color: "#fff", 
    fontSize: "16px", 
    top: 6,
    fontFamily:"Lato",
    padding:0,
    whiteSpace:"nowrap",
    textTransform: "none",
    fontWeight:400,
     "&:hover": {
      backgroundColor: "transparent",
    },
  },
  navButton: {
   // marginLeft: "auto",
  whiteSpace:"nowrap",
  marginLeft: "auto",
  
    cursor: "pointer",
  },
  logo: {
    cursor: 'pointer'
  },
  linkImg: {
    cursor: 'pointer'
  },
  button: {
    cursor: 'pointer'
  },

  drawer: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: "1.5em",
  },
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: theme.palette.primary.main,
    paddingTop: "1em",
    paddingBottom: "1em",
   
  },

  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "1em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1em",
    },
  },

  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    color: theme.palette.common.white,
    height: "30px",
    width: "30px",
  },
  drawerItem: {
    paddingTop: "1.5em",
    paddingBottom: "1.5em",
  },
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const iOS = process.browser && /iPad|iPhone|ipod/.test(navigator.userAgent);
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [openDrawer, setOpenDrawer] = useState(false);

  const history = useHistory();

  const tabs = (
    <Grid
      item
      container
      direction={matchesSM ? "column" : "row"}
      alignItems={"flex-end"}
      
    >
      <Grid item className={classes.navLogo}   lg={4}
          md={2}
          sm={8}
          xs={8}>
        <div>

        <Button
          variant="text"
          disableRipple
          className={classes.navLinkText}
          onClick={() => history.push("/")}>
             <img src={klippitLogo} style={{ marginRight: 16, height:"26px" }}/>
             <b>For Merchants</b>
        </Button>
        </div>
      </Grid>
      <Grid item container style={{marginLeft:"auto"}}   lg={6}
          md={9}
          sm={12}
          xs={12} >
      <Grid item className={classes.navLink}  sm={2}>
        <Button
          variant="text"
          disableRipple
          className={classes.navLinkText}
          onClick={() => history.push("/whyKlippit")} >
          Why Klippit
        </Button>
      </Grid>
      <Grid item className={classes.navLink} sm={2}>
        <Button
          disableRipple
          variant="text"
          className={classes.navLinkText}
          onClick={() => history.push("/how-it-works")}>
            How it Works
        </Button>
      </Grid>
      <Grid item className={classes.navLink} sm={2}>
        <Button
          disableRipple
          variant="text"
          className={classes.navLinkText}
          onClick={() => history.push("/login?new=false")}>
            Login
        </Button>
     
      </Grid>
      <Grid item className={classes.navButton} sm={2}  >
        <GetStartedButton disableRipple variant="contained" 
            style={{top: 0}}
            onClick={() => history.push("/login")} className={classes.navButton}> 
                Get Started
        </GetStartedButton>
      </Grid>
    </Grid>
    </Grid>
  );

  const drawer = (
    <Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          <ListItem className={classes.drawerItem}>
                <Button
                  variant="text"
                  style={{color: "#fff", fontSize: 16}}
                    onClick={() => {
                      history.push("/whyKlippit");
                      setOpenDrawer(false);
                    }}>
                    Why Klippit
                </Button>

           { /*<img
              src={whyKlippit}
              alt="Why Klippit"
              onClick={() => {
                history.push("/whyKlippit");
                setOpenDrawer(false);
              }}
              className={classes.linkImg}
            />*/}
          </ListItem>
          <ListItem className={classes.drawerItem}>
                <Button
                  variant="text"
                  style={{color: "#fff", fontSize: 16}}
                  onClick={() => {
                      history.push("/how-it-works");
                      setOpenDrawer(false);
                  }}>
                    How it works
                </Button>
          </ListItem>
          <ListItem className={classes.drawerItem}>
                <Button
                  variant="text"
                  style={{color: "#fff", fontSize: 16}}
                    onClick={() => {
                      history.push("/press");
                      setOpenDrawer(false);
                    }}>
                        Login
                </Button>
          </ListItem>
          <ListItem className={classes.drawerItem}>
          <GetStartedButton variant="contained" 
            style={{top: 0}}
            onClick={() => history.push("/login")} > 
                Get Started
        </GetStartedButton>
          </ListItem>
        </List>
      </SwipeableDrawer>

      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
        className={classes.drawerIconContainer}
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </Fragment>
  );

  return (
    <Fragment>
      {!matchesSM && (
        <Grid item container alignItems={"center"} className={classes.navBar}>
          {tabs}
        </Grid>
      )}

      {matchesSM && (
        <Fragment>
          <ElevationScroll>
            <AppBar position={"fixed"} className={classes.appBar}>
              <Toolbar>
                <img
                  src={klippitLogo}
                  alt="Klippit Logo"
                  onClick={() => history.push("/")}
                  className={classes.logo}
                />
                {drawer}
              </Toolbar>
            </AppBar>
          </ElevationScroll>
          <div className={classes.toolbarMargin} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Header;
