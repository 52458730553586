import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import LoginForm from "../components/LoginForm";
import SignupForm from "../components/SignupForm";

import KlippitIcon from "../components/KlippitIcon";

const Login = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  
  useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const gettingStarted = urlParams.get('new');
        if (gettingStarted == 'false') {
            setShowLoginForm(true)
        }
        if (gettingStarted == 'true') {
            setShowSignupForm(true);
        }
        setIsLoading(false);
  }, [])

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  if (isLoading) {
    return <></>
  }

  if (showLoginForm) {
    return (
        <>
            <div style={{ width: "100vw", height: "95vh", margin: '16px 0px' }}>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: "column", alignItems: 'center',  overflow: 'scroll' ,margin: "32px 0px"}}>
                    <div style={{ padding: '32px', justifyContent: 'center', alignItems: 'center' }}>

                    <KlippitIcon />

                     </div>
                <div style={{ paddingTop: "16px" }} >
                <LoginForm showSignupForm={() => { 
                    setShowLoginForm(false);
                    setShowSignupForm(true);
                 }} /> 
            </div>
          </div> 
         </div>
        </>
    )
  }
  else if (showSignupForm) {
     return (
        <>
            <div style={{ width: "100vw", height: "95vh", margin: '16px 0px' }}>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: "column", alignItems: 'center',  overflow: 'scroll' ,margin: "32px 0px"}}>
                    <div style={{ padding: '32px', justifyContent: 'center', alignItems: 'center' }}>

                    <KlippitIcon />
                </div>
                <div style={{ paddingTop: "16px" }} >
                    <SignupForm showLoginForm={() => { 
                        setShowLoginForm(true);
                        setShowSignupForm(false);
                     }} /> 
                </div>

            </div>
          </div> 
        </>
    )
  }

  
  else {
  return (
    <>
      <div style={{ width: "100vw", height: "95vh", margin: '16px 0px' }}>
          <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: "column", alignItems: 'center',  overflow: 'scroll' ,margin: "32px 0px"}}>
              <div style={{  padding: '32px', justifyContent: 'center', alignItems: 'center' }}>

                    <KlippitIcon />
                </div>

                    <h2 style={{ margin: "8px" }}>Welcome to Klippit for Merchants</h2>
                    <p>Login or Signup to continue using Klippit</p>

                <div style={{ width: '50%', display: 'flex', flexDirection: "row", justifyContent: "center"}}>
                    <Button variant="contained" style={{ width: '150px', margin: "8px 8px" , backgroundColor: '#1190ad' , color: '#fff' }}
                        onClick={() => setShowLoginForm(true)}
                    >Login</Button>
                    <Button variant="contained" style={{ width: '150px', margin: "8px 8px" , backgroundColor: '#1190ad' , color: '#fff' }}
                        onClick={() => setShowSignupForm(true)}
                    >Signup</Button>
                </div>
                
            </div>
        </div>
    </>
  );
};
}


export default Login;
