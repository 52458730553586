import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import TextField from '@material-ui/core/TextField';



const SignupForm = (props) => {
    const [credentials, setCredentials] = useState({email: "", pass: ""})
    const [email, setEmail] = useState("")
    const [confirmEmail, setConfirmEmail] = useState("")
    const [pass, setPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [showError, setShowError] = useState(false)
    const [validationError, setValidationError] = useState({
        email: false,
        confirmEmail: false,
        pass: false,
        confirmPass: false,
    })
    const history = useHistory();


    async function signupPressed(e) {
        e.preventDefault();

        const isInvalid = Object.values(validationError).some(val => val === true)
        if (isInvalid ) {
            let msg;
            if (validationError.email) {
                msg = "please make sure email is valid";
            }
            else if (validationError.confirmEmail) {
                msg = "Emails do not match";
            }
            else if (validationError.pass) {
                msg = "Please fill out password field";
            }
            else if (validationError.confirmPass) {
                msg = "Passwords do not match";
            }
            setShowError(msg || "An unknown error occurred.")
            return
        }

      try {
        const response = await fetch(
            `/api/v1/accounts/signup?type=merchant&finish=false`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: email,
                  password: pass,
                }),
              }
        );

        const responseData = await response.json();
        if (!response.ok) {
           throw new Error(responseData.message);
        }

        if (responseData.status === 'failure') {
            //display alert already exists
            console.error(responseData.message)
            const error = responseData.message
            setShowError(error || "Something went wrong. Please try again");
            return
        }
           history.push({
            pathname: '/signup',
            search: `?step=0`,
           })
        
       } catch (error) {
          console.log(error);
          setShowError(error.message || "Something went wrong. Please try again");
       }

    }

    const emailChange = (e) => {
        setEmail(e.target.value)
        if (e.target.value !== confirmEmail) {
            setValidationError({...validationError, confirmEmail: true})
        }
        else {
            setValidationError({...validationError, confirmEmail: false})
        }
    }
 
    
    const confirmEmailMatch = (e) => {
        if (e.target.value !== email) {
            setValidationError({...validationError, confirmEmail: true})
        }
        else {
            setValidationError({...validationError, confirmEmail: false})
        }
        setConfirmEmail(e.target.value)
    }

    const validateEmail = (e) => {
        const validEmail = String(e.target.value)
        .toLowerCase()
        .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (!validEmail) { setValidationError({...validationError, email: true}) }
        else { setValidationError({...validationError, email: false}) }
    }
 

    const passwordChange = (e) => {
        setPass(e.target.value)
        if (e.target.value !== confirmPass) {
            setValidationError({...validationError, confirmPass: true})
        }
        else {
            setValidationError({...validationError, confirmPass: false})
        }
    }
 
    
    const confirmPasswordMatch = (e) => {
        if (e.target.value !== pass) {
            setValidationError({...validationError, confirmPass: true})
        }
        else {
            setValidationError({...validationError, confirmPass: false})
        }
        setConfirmPass(e.target.value)
    }
 

    return (
        <div style={{ width: '30vw', minWidth: '200px' }}>
            <div style={{ height: '30%', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>

                {showError
                    ?
                        <Alert severity="error">{showError}</Alert>
                    :
                    <></>
                }

                <h3 style={{ margin: "8px" }}>Create your account</h3>

                <form style={{ width: '100%'}} onSubmit={signupPressed}> 
                <div style={{ marginTop: "8px", width: '100%', display: 'flex', flexDirection: "column", justifyContent: "center"}}>
                    <TextField  size="small" id="outlined-basic-email" label="Email Address" variant="outlined" style={{ marginTop: "8px" }}
                        error={validationError.email}
                        value={email}
                        onChange={emailChange}
                        onBlur={validateEmail}
                        onInput={e => setEmail(e.target.value)}
                        required
                     />
                    <TextField size="small" id="outlined-basic-email-confirm" label="Confirm Email Address" variant="outlined" style={{ marginTop: "8px" }}
                        error={validationError.confirmEmail}
                        value={confirmEmail}
                        onChange={confirmEmailMatch}
                        onInput={e => setConfirmEmail(e.target.value)}
                        required
                     />
                    <TextField type="password" size="small" id="outlined-basic-pw" label="Password" variant="outlined" style={{ marginTop: "8px" }}
                        error={validationError.pass}
                        value={pass}
                        onChange={passwordChange}
                        onInput={e => setPass(e.target.value)}
                        required
                     />
                    <TextField type="password" size="small" id="outlined-basic-pw-confirm" label="Confirm Password" variant="outlined" style={{ marginTop: "8px" }}
                        error={validationError.confirmPass}
                        value={confirmPass}
                        onChange={confirmPasswordMatch}
                        onInput={e => setConfirmPass(e.target.value)}
                        required
                     />
                    <p style={{ textAlign: 'center', marginTop: 16, fontSize: 11 }}>
                        By clicking "Create Account", you agree to our 
                        <span 
                            style={{ cursor: "pointer", color : "#1190ad" }}
                            onClick={() => {
                                history.push("/terms");
                            }}     
                        > Terms </span>
                        <br />
                        and confirm you're 18 years or older
                    </p>

                    <Button 
                        type="submit"
                        variant="contained" 
                        style={{ 
                            width: '100%', 
                            marginTop: "16px" , 
                            backgroundColor: '#1190ad' , 
                            color: '#fff' }}
                    >
                        Create Account
                    </Button>
                </div>
                
                </form>
                <p style={{ textAlign: 'center', marginTop: 16, fontSize: 11 }}>
                        Already have an account? Login
                        <span 
                            style={{ cursor: "pointer", color : "#1190ad" }}
                            onClick={props.showLoginForm}     
                        > here </span>
                </p>
                
            </div>
        </div>
    )
}

export default SignupForm;
