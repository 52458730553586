import React from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import { Fade, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "../components/Divider"
import Button from "@material-ui/core/Button";


import squareTiles from "../assets/homePage/homepage1.png";
import connectImg from "../assets/homePage/homefooter.png";
import mainButton from "../assets/homePage/main_button.png";
import roundSphere from "../assets/homePage/homepage2.png";
import iPhoneMockup from "../assets/homePage/iPhoneMockupHP.png";

// NEWS IMAGES
import news1Image from "../assets/homePage/news1.png";
import news2Image from "../assets/homePage/news2.png";
import news3Image from "../assets/homePage/news3.png";
import news4Image from "../assets/homePage/news4.png";
import news5Image from "../assets/homePage/news5.png";
import news6Image from "../assets/homePage/news6.png";

// TESTIMONAL IMAGES
import card1Image from "../assets/homePage/testimonals/card1.png";
import card2Image from "../assets/homePage/testimonals/card2.png";
import card3Image from "../assets/homePage/testimonals/card3.png";

/* Blog */
import BlogCard from "../components/BlogCard";

import Typography from "@material-ui/core/Typography";
import TestimonalCard from "../ui/TestimonalCard";

import { useHistory } from "react-router-dom";


const GetStartedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  //padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  width: "250px",
  height: "50px",
  backgroundColor: '#108DAA',
  borderColor: '#108DAA',
  color: '#fff',
  borderRadius: "30px",
  cursor: "pointer",
  whiteSpace:"nowrap",
  
 fontFamily: "Lato-Bold",
  '&:hover': {
    borderColor: '#108DAA',
    backgroundColor: '#108DAA',
    boxShadow: 'none',
  },
  
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #108DAA',
    
  },
});
const useStyles = makeStyles((theme) => ({
  pageStyle:{
/*
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),*/
    paddingLeft:"100px",
    paddingRight:"100px",
    [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
      paddingRight: "0px",
    }
  },
  container: {
    paddingTop: "60px",
    paddingBottom: "106px",
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  footerContainer: {
   // paddingTop: "60px",
   // paddingBottom: "106px",
    backgroundColor: "#8ac5cc",
    height:"550px",
color:"#fff",
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      height:"100%",
    },
  },
  heading: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
    paddingRight: "20px",
    fontSize: "35px",
    fontFamily: "Arvo-Bold",
    color: "#544F55",
    width:"85%",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
      width:"100% !Important",
    // paddingLeft: "0px !important",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      textAlign: "center",
      width:"100%",
    },
  },
  headerFooter: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
   // paddingRight: "20px",
    fontSize: "24px",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontFamily:"Lato",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      width:"100%",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width:"100%",
      textAlign: "center",
    },
  },
  subHeading: {
   // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   width:"73%",
   color: "#47525E",
   fontFamily:"DroidSans",
   fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px !Important",
      width:"90% !Important",
      //textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !Important ",
      textAlign: "center",
      width:"100%",
      paddingRight: "0px !Important",
    },
  },
  subHeaderFooter: {
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontSize: "18px",
    fontFamily:"Lato",
     [theme.breakpoints.down("md")]: {
       fontSize: "20px",
       //textAlign: "center",
     },
     [theme.breakpoints.down("sm")]: {
       fontSize: "15px",
       textAlign: "center",
       width:"100%",
     },
   },
   
  rightTextContainer:{
  
   [theme.breakpoints.down("md")]: {
    paddingLeft:"60px !Important",
    paddingRight:"50px !Important"
   },
   [theme.breakpoints.down("sm")]: {
    paddingLeft:"0px !Important",
    paddingRight:"0px !Important"
   },
  },
 newsSection:{
  paddingLeft: "128px", 
  paddingRight: "128px",

  [theme.breakpoints.down("md")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
 },
 newsTileContainer:{
  [theme.breakpoints.down("md")]: {

  },
  [theme.breakpoints.down("sm")]: {
   justifyContent: "normal !Important",
   margin:"0px !Important",
  },

 },
  iPhone: {
    width: "100%",
  },
  imageTiles: {
    width: "100%",
 
  },

  mainLeftContainer: {
   //paddingLeft: "4em",
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
    //  paddingLeft: "100px !Important",
      paddingRight: "0",
    },
  
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !Important",
      paddingRight: "1.5em",

    },
  },
  mainLeftContainerFooter: {
    color:"#fff",
   height:"100%",
    paddingLeft: "20em",
     paddingRight: "6em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
     [theme.breakpoints.down("sm")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
   },
  mainRightContainer: {
   paddingLeft: "2em",
    //paddingRight: "4em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5em !Important",
      paddingRight: "1.5em !Important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1.5em",
      paddingLeft: "1.5em !Important",
      paddingRight: "1.5em !Important",
    },
  },
  mainRightContainerFooter: {
    paddingLeft: "2em",
    height:"100%",
     //paddingRight: "4em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em",
      // paddingRight: "1.5em",
     },
     [theme.breakpoints.down("sm")]: {
       paddingTop: "1.5em",
       paddingLeft: "1.5em",
     paddingRight: "1.5em",
     alignItems:"center"
     },
   },
  testimonalContainer: {
    backgroundColor: "#8AC5CC",
    paddingTop: "66px",
    paddingLeft: "140px",
    paddingRight: "140px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },

}));

const testimonals = [
  {
    id: 0,
    image: card1Image,
    info:
      "I think this will be good for micro influencers.",
    rating: 4,
    name: "Kyle Jones",
    type: "Influencer",
  },
  {
    id: 1,
    image: card2Image,
    info:
      "This app will be great with helping businesses market with influencers. Game-changer.",
    rating: 4,
    name: "Evan Cooper",
    type: "Influencer",
  },
  {
    id: 2,
    image: card3Image,
    info:
      "I would recommend this for any business considering influencer marketing on a budget. You only pay when it works.",
    rating: 4,
    name: "Eduardo Watson",
    type: "Merchant",
  },
];

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  return (
   <> <Grid container alignItems={"center"} direction={"column"} className={classes.pageStyle}>
      {/*PHOTO TILES*/}
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}

      >
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainLeftContainer}
        >
          <Grid
            item
            container
            alignItems={matchesSM ? null : "flex-end"}
            direction={"column"}
          >
            <Grid item>
              <Grid
                item
                container
                direction={"column"}
                alignItems={matchesSM ? "center" : null}
              >
                <Grid item>
                  <Typography variant={"h1"} className={classes.heading}>
                    Revolutionizing the Coupon Game: How Klippit is Using Coupons to Power Influencer Marketing
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: "42px" }}>
                  <Typography variant={"h5"} className={classes.subHeading}>
                    Join the revolution and discover how Klippit is revolutionizing the way businesses
                    use coupons to create effective influencer marketing campaigns that drive results.
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "60px" }}>
                  <GetStartedButton variant="contained"
                    style={{ top: 0 }}
                    onClick={() => history.push("/login")}>
                    Get Started
                  </GetStartedButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainRightContainer}
          style={{ paddingTop: "40px" }}
        >
          <img
            src={squareTiles}
            alt="Square Tiles"
            className={classes.imageTiles} />
        </Grid>
      </Grid>



      {/*ROW 2 SPHERE*/}
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}

      >
        <Grid
          item

          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainLeftContainer}
          style={{paddingLeft:"100px"}}
        >

          <Fade in={true} timeout={3000}>
            <img
              src={roundSphere}
              alt="Round Sphere"
              className={classes.imageTiles} />
          </Fade>
        </Grid>


        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainRightContainer}

        >
          <Grid
            item
            container
            alignItems={matchesSM ? null : "flex-end"}
            direction={"column"}
            className={classes.rightTextContainer}
            style={{paddingRight:"100px",paddingLeft:"100px"}}
          >
            <Grid item>
              <Grid item container alignItems={matchesSM ? "center" : null} direction={"column"}>

                <Grid item style={{ marginTop: "100px" }}>
                  <Typography variant={"h2"} className={classes.heading} style={{fontFamily:"DroidSerif-Bold", fontSize: "25px", width: "80%"}}>
                    Innovative Coupon-Based Influencer Marketing with Klippit
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: "16px" }}>
                  <Typography variant={"h5"} className={classes.subHeading} style={{fontFamily:"DroidSans",fontSize:"15px",width:"80%", paddingRight:"60px"}}>
                    Find out how Klippit is transforming the way businesses approach influencer marketing by using coupons as a powerful tool for driving engagement and conversions.

                  </Typography>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />
      <br />

      {/*News Section*/}
      <div style={{ paddingTop: "100", alignItems: 'center' }}>
        <div style={{ borderBottomColor: '#bdbdbd', borderBottomWidth: "1", width: '90%' }} />
        <div style={{ position: 'relative' }}>
          <Divider />
        </div>
      </div>
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}

      >
        <Grid
          item
          container
          direction={"row"}
          style={{
            paddingBottom: "60px",
            backgroundColor: "#fff",
          }}

        >


          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100vw", paddingBottom: "80px", textAlign: "center" }}
            className={classes.newsSection}
          >
            <Grid item style={{ marginTop: "32px" }}>
              <Typography variant={"h2"} style={{fontFamily:"DroidSerif-Bold", fontSize: "40px" }}>
                News, Resources, and Events
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "16px" }}>
              <Typography variant={"h6"} style={{fontFamily:"DroidSans", fontSize: "18px" }}>
                Stay up-to-date on the latest news and events from Klippit and the world of influencer marketing.


              </Typography>
            </Grid>
          </Grid>

    <Grid sx={{ flexGrow: 1 }} container spacing={10} style={{ justifyContent: 'center' }} className={classes.newsTileContainer}>
      <Grid item xs={1} sm={10} md={12} lg={10} xl={6}>
        <Grid container spacing={10} columns={{ xs: 4, sm: 16, md: 20, lg: 24 }} justifyContent="center" className={classes.newsTileContainer}>
            <Grid item xs={2} sm={4} md={4} >
                <div>
                    <BlogCard 
                        title={"News: Atlanta Business Radio Interview with Klippit"}
                        subtitle={"In this flashback interview, Chike, founder at Klippit explains how Klippit allows influencers to turn…"}
                        image={news1Image}
                        handlePress={() => history.push("/blog?a=3")}
                />
                </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} >
                <div>
                    <BlogCard 
                        title={"News: Interview with \"Atlanta Startup Podcast\""}
                        subtitle={"Chike talks about influencer marketing in this flashback interview with Atlanta Startup Po…"}
                        image={news2Image}
                        handlePress={() => history.push("/blog?a=4")}
                        
                />
                </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} >
                <div>
                    <BlogCard 
                        title={"Blog: Are Coupons Still Effective for Small Businesses"}
                        subtitle={"In today’s market, using coupons as a marketing strategy for businesses is no longer as effective as it use…"}
                        image={news3Image}
                        handlePress={() => history.push("/blog?a=1")}
                />
                </div>
            </Grid>
        
        
            <Grid item xs={2} sm={4} md={4} >
                <div>
                    <BlogCard 
                        title={"News: Klippit Featured in Hypeptamus Article"}
                        subtitle={"Check out this article by Hypepotamus that features Klippit and talks about its early days"}
                        image={news4Image}
                        handlePress={() => history.push("/blog?a=5")}
                />
                </div>
            </Grid>
        
            <Grid item xs={2} sm={4} md={4} >
                <div>
                    <BlogCard 
                        title={"Blog: A Story of How Nick Violas Used Influencers to Grow His Restaurant"}
                        subtitle={"Nick Violas, owner of Nolan's, a 270-seat, steak-and-seafood restaurant with striking views…"}
                        image={news5Image}
                        handlePress={() => history.push("/blog?a=0")}
                />
                </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} >
                <div>
                    <BlogCard 
                        title={"Blog: How a Utah-based e-commerce store used influencer marketing to..."}
                        subtitle={"In today’s market, using coupons as a marketing strategy for businesses is no longer as effective as it use…"}
                        image={news6Image}
                        handlePress={() => history.push("/blog?a=2")}
                />
                </div>
            </Grid>
        </Grid>
    </Grid>
</Grid>


          {/*-----*/}
        </Grid>
      </Grid>
    </Grid>
     {/*CONNECT*/}
     <Grid
     item
     container
     direction={matchesSM ? "column" : "row"}
     className={classes.footerContainer}
     
     >
    


     <Grid
       item
       lg={7}
       md={7}
       sm={12}
       xs={12}
       className={classes.mainLeftContainerFooter}
       
     >
       <Grid
         item
         container
         alignItems={matchesSM ? null : "flex-end"}
         
         direction={"column"}
       
       >
         <Grid item>
           <Grid item container alignItems={matchesSM ? "center" : null} direction={"column"} style={{color:"#fff"}}>
             
             <Grid item style={{ marginTop: "100px" }}>
               <Typography variant={"h4"} className={classes.headerFooter} >
               Connect with your audience like never before with Klippit.
               </Typography>
             </Grid>

             <Grid item style={{ marginTop: "26px" }}>
               <Typography variant={"h6"}  className={classes.subHeaderFooter}>
               Forge deeper connections with your audience and create authentic, engaging content that drives results.
               </Typography>
             </Grid>

             <Grid item style={{ marginTop: "50px" }}>
             <GetStartedButton variant="contained" 
         style={{top: 0, backgroundColor: "#fff", color:"#108DAA", fontWeight:600, borderColor:"#fff", width: "160px"}}
         onClick={() => history.push("/login")} > 
             Get Started
     </GetStartedButton>
     </Grid>

           </Grid>
         </Grid>
       </Grid>
       </Grid>
       <Grid
       item
      
       lg={5}
       md={5}
       sm={12}
       xs={12}
       className={classes.mainRightContainerFooter}
     >
       <img
         src={connectImg}
         alt="Connect Image"
         style={{height: "100%"}}
         className={classes.imageTiles}
       />
       
     </Grid>
      
    
   </Grid></>
  );
};

export default Home;
