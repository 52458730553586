import React, { Fragment } from "react";

import { Switch, Route } from "react-router-dom";
import Header from "./ui/Header";
import Home from "./pages/Home";
import Newsletter from "./ui/Newsletter";
import Footer from "./ui/Footer";
import WhyKlippit from "./pages/WhyKlippit";
import Demo from "./pages/Demo";
import Press from "./pages/Press";
import Login from "./pages/Login";
import SignupDetails from "./pages/SignupDetails";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Blog from "./pages/Blog";
import HowItWorks from "./pages/HowItWorks";

import FbPixel from "./components/FbPixel";
import FbConversionPixel, { FbConversionEvent, testevent } from "./components/FbMetaApi";

const App = () => {
  return (
    <Fragment>

      <FbPixel />
      <FbConversionPixel />

      <Switch>
        <Route exact path={"/"}>
          <FbConversionEvent eventName="ViewContent" data={ {content_name: 'Landing'} } />
          <Header />
          <Home />
          <Footer />
        </Route>
        <Route exact path={"/whyKlippit"}>
          <FbConversionEvent eventName="ViewContent" data={ {content_name: 'Why-Klippit'} } />
          <Header />
          <WhyKlippit />
          <Newsletter />
          <Footer />
        </Route>

        <Route exact path={"/how-it-works"}>
          <FbConversionEvent eventName="ViewContent" data={ {content_name: 'How-It-Workds'} } />
          <Header />
          <HowItWorks /> 
          <Footer />
        </Route>

        <Route exact path={"/demo"}>
          <Header />
          <Demo />
          <Newsletter />
        </Route>
        <Route exact path={"/press"}>
          <Header />
          <Press />
          <Newsletter />
        </Route>
        <Route exact path={"/login"}>
          <FbConversionEvent eventName="ViewContent" data={ {content_name: 'Get Started'} } />
          <Login />
        </Route>
        <Route exact path={"/signup"}>
          <SignupDetails />
        </Route>

        <Route exact path={"/terms"}>
          <Header />
          <Terms />
          <Footer />
        </Route>

        <Route exact path={"/privacy"}>
          <Header />
          <Privacy />
          <Footer />
        </Route>

        <Route exact path={"/blog"}>
          <Header />
          <Blog />
          <Footer />
        </Route>

      </Switch>
    </Fragment>
  );
};

export default App;
