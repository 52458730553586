import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";

import { useHistory, Redirect } from "react-router-dom";
import CalendlyCalendar from "../components/CalendlyCalendar";

import KlippitIcon from "../components/KlippitIcon";
import "./styles.css";

import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import Alert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';


import TextField from '@material-ui/core/TextField';
import addImg from '../assets/add.png';


const SignupDetails = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [mid, setMid] = useState();

    const [loading, setLoading] = useState(false);
    const timer = React.useRef();
    const history = useHistory();

    const [showError, setShowError] = useState(false)
    const [promoImage, setPromoImage] = useState(null)
    const [submitFinish, setSubmitFinish] = useState(false)
    const promoImageRef = useRef(null) 

    const [merchant, setMerchant] = useState({
        "company": '',
        "fname": '',
        "lname": '',
        "phone": '',
        "promoName": '',
        "promoStdPrice": '',
        "promoDiscount": '',
        "promoQty": '',
    })

    useEffect(() => {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const step = urlParams.get('step');
          try {
            setCurrentStep(parseInt(step));
          } catch (e) {
            history.push('/')
          }
          initialize()
          return () => {
            clearTimeout(timer.current);
          };
    }, [])

    function initialize() {
        if (sessionStorage.getItem(`${mid}`)) {
            try {
                setMerchant(JSON.parse(sessionStorage.getItem(`${mid}`)));
            } catch {
                console.error("error parsing json");
            }
        }
        window.addEventListener("beforeunload", function (e) {
            if (submitFinish) {
                return undefined;
            }
              var confirmationMessage = 'resubmit session'
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            //return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        });
    }

    const videoStepUrl = () => {
        if (currentStep == 0) {
            return 'https://www.youtube.com/embed/AG0CF8BsS_I?autoplay=1'
        }
        else if (currentStep == 1) {
            return 'https://www.youtube.com/embed/D6_lP_3pCyg?autoplay=1'
        }
        else if (currentStep == 2) {
            return 'https://www.youtube.com/embed/8B_5cJpJYv4?autoplay=1'
        }
        else if (currentStep == 3) {
            return 'https://www.youtube.com/embed/9AbWU8asia4?autoplay=1'
        }
        else if (currentStep == 4) {
            return 'https://www.youtube.com/embed/SGupXsINPAs?autoplay=1'
        }
    }

    function updateStorage(newItems) {
        try {
            const json = JSON.stringify({...merchant, ...newItems})
            sessionStorage.setItem(`${mid}`, json)
            setMerchant({...merchant, ...newItems})
        } catch {
            console.error("error parsing json");
        }
    }

    function normalizeData(obj, newKey, oldKey) {
        Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey));
        delete obj[oldKey];
    }

    async function completeSignup (data) {
      try {
        const response = await fetch(
            `/api/v1/accounts/signup?type=merchant&finish=true`,
            {
                method: 'POST',
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
              }
        );

        const responseData = await response.json();
        if (!response.ok) {
           throw new Error(responseData.message);
        }
        if (responseData.status !== 'success') {
          const error = responseData.message
          setShowError(error || "Something went wrong. Please try again");
          return
        }
            //updateStorage({ promoId: responseData.id });
            
            const nextStep = currentStep + 1
            setCurrentStep(nextStep)
            //history.push({ pathname: '/signup', search: `?step=${nextStep}&mid=${mid}`, })
            window.history.replaceState(null, null, `/`);
       } catch (error) {
          console.error(error);
          setShowError(error.message || "Something went wrong. Please try again");
       }

    }



    async function update (url, method, data) {
      try {
        const response = await fetch(
            `${url}`,
            {
                method: method,
                credentials: "include",
//                headers: {
//                  "Content-Type": "application/json",
//                },
                body: data
              }
        );

        //const responseData = await response.json();
        if (!response.ok) {
           const json = await response.json();
           throw new Error(json.message);
        }
        //if (responseData.success) {
            //updateStorage({ promoId: responseData.id });
            
            const nextStep = currentStep + 1
            setCurrentStep(nextStep)
            //history.push({ pathname: '/signup', search: `?step=${nextStep}&mid=${mid}`, })
            window.history.replaceState(null, null, `/`);
        //}
       } catch (error) {
          console.error(error);
          setShowError(error.message || "Something went wrong. Please try again");
       }

    }

    const showNextStep = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target)
        const entries = Object.fromEntries( data )
        updateStorage(entries) 

        if (currentStep == 0) {
            normalizeData(entries, 'name', 'company');
            normalizeData(entries, 'firstName', 'fname');
            normalizeData(entries, 'lastName', 'lname');
            normalizeData(entries, 'phoneNumber', 'phone');
            //await update(`merchant/${mid}`, "PUT", entries)
            completeSignup(entries)
        }
        else if (currentStep == 3) {
            const newObject = {...merchant, ...entries};
            console.log('formdata =>' , data)
            data.append('campaign_qty', newObject.promoQty);
            data.append('campaign_price', newObject.promoStdPrice);
            data.append('campaign_discount', newObject.promoDiscount);
            data.append('campaign_name', newObject.promoName);
            //normalizeData(newObject, 'maxPromos', 'promoQty');
            //normalizeData(newObject, 'price', 'promoStdPrice');
            //normalizeData(newObject, 'dealInfo', 'promoDiscount');
            //normalizeData(newObject, 'summary', 'promoName');
            //newObject.name = merchant.promoName
            await update(`/api/v1/campaigns/startnew`, "POST", data)
        }
        else {
            const nextStep = currentStep + 1
            setCurrentStep(nextStep)
            //history.replace({ pathname: '/signup', search: `?step=${nextStep}&mid=${mid}`, })
            //window.location.replace(`/signup?step=${nextStep}&mid=${mid}`);
            window.history.replaceState(null, null, `/`);
        }
    }

    const showPrevStep = () => {
        const nextStep = currentStep - 1
        setCurrentStep(nextStep)
        //history.replace({ pathname: '/signup', search: `?step=${nextStep}&mid=${mid}`, })
        //window.location.replace(`/signup?step=${nextStep}&mid=${mid}`);
        window.history.replaceState(null, null, `/`);
    }

    function submitSignupDetails(e) {
        e.preventDefault();
        setSubmitFinish(true)
        if (!loading) {
          setLoading(true);
        }

        timer.current = window.setTimeout(() => {
            setLoading(false);
            //window.location.href = `${process.env.REACT_APP_MERCHANT_DOMAIN}/segue?mid=${mid}`;
            window.location.replace(`/merchant/overview`);
        }, 1000);
    }

    function onChange(event) {
        setMerchant({
            ...merchant,
            [String(event.target.name)]: String(event.target.value)
        })
    }

    function triggerPixel(step) {
        return (<>
                        <Helmet>
                            <script>
                            {`
                                if (typeof fbq === "function") { 
                                    fbq('track', 'ViewContent', { content_name: 'signup_step_${step}' });
                                    c_api("ViewContent", { content_name: 'signup_step_${step}' })
                                }
                            `}
                            </script>
                        </Helmet>
        </>)   
    }


    const renderSignupStep = (step) => {
        switch(step) {
            case 0:
                return (<>

                        {triggerPixel(step)}                    

                        <h3 style={{ marginTop: "32px", fontFamily: "Roboto", fontWeight: "bold", color: "#47525E", marginBottom: "50px"}}>Tell us about you</h3>
                        <form style={{ width: '100%'}} onSubmit={showNextStep}> 
                            
                            <div style={{ marginTop: "8px", width: '100%', display: 'flex', flexDirection: "column", justifyContent: "center"}}>

                                <TextField value={merchant.company} onChange={onChange} name="company" size="small" id="merchant-name" label="Company Name" variant="outlined" style={{ marginTop: "8px" }}
                                    required
                                />

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                                     <TextField value={merchant.fname} onChange={onChange} name="fname" size="small" id="merchant-fname" label="First Name" variant="outlined" style={{ marginTop: "8px", marginRight: "8px" }}
                                         required
                                     />
                                     <TextField value={merchant.lname} onChange={onChange} name="lname" size="small" id="merchant-lname" label="Last Name" variant="outlined" style={{ marginTop: "8px", marightLeft: "8px" }}
                                         required
                                     />
                                </div>

                                <TextField value={merchant.phone} onChange={onChange} maxlength="10" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="phone" size="small" id="merchant-phone" label="Phone Number" variant="outlined" style={{ marginTop: "8px" }}
                                    placeholder="111-123-4567"
                                    required
                                />

                                <Button 
                                    type="submit"
                                    variant="contained" 
                                    style={{ 
                                        width: '100%', 
                                        marginTop: "16px" , 
                                        backgroundColor: '#1190ad' , 
                                        color: '#fff' }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                </>)
            case 1:
                return (<>

                        {triggerPixel(step)}                    

                        <h3 style={{ marginTop: "32px" }}>What type of promotion are you offering?</h3>
                        <form style={{ width: '100%'}} onSubmit={showNextStep}> 
                            
                            <div style={{ marginTop: "8px", width: '100%', display: 'flex', flexDirection: "column", justifyContent: "center", marginBottom:"30px"}}>

                                <TextField value={merchant.promoName} onChange={onChange} maxlength="120" minlength="10" name="promoName" size="medium" id="promo-name-a" label="" 
                                
                                variant="outlined" style={{ marginTop: "8px", }}
                                    required 
                                />
                                <p style={{fontSize: 11, textAlign: 'center', marginTop: 10}}>
                                    For example: 60-Minute Jump Pass for One Person
                                </p>
                                <Button 
                                    type="submit"
                                    variant="contained" 
                                    style={{ 
                                        width: '100%', 
                                        marginTop: "16px" , 
                                        backgroundColor: '#1190ad' , 
                                        color: '#fff' }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                        <Button 
                            variant="text" 
                            style={{ 
                                width: '100%', 
                                marginTop: "16px" , 
                                color: '#1190ad' , 
                            }}
                            onClick={showPrevStep}
                        >
                            Previous
                        </Button>


                </>)
            case 2:
                return (<>

                        {triggerPixel(step)}                    

                        <form style={{ width: '100%'}} onSubmit={showNextStep}> 
                            
                            <div style={{ marginTop: "8px", width: '100%', display: 'flex', flexDirection: "column", justifyContent: "center"}}>

                                <h3 style={{ marginTop: "32px" }}>What is the normal price of you product/service?</h3>
                                <TextField value={merchant.promoStdPrice} onChange={onChange} type="number" name="promoStdPrice" size="small" id="promo-std-price" label="$" variant="outlined" style={{ marginTop: "8px" }}
                                type="number" min="5" max="999"
                                    required
                                />
                                <p style={{fontSize: 11, textAlign: 'left', marginTop: 10}}>
Regular price should be between $5 and $999
                                </p>

                                <h3 style={{ marginTop: "32px" }}>How much of a discount would you like to offer?</h3>
                                <TextField value={merchant.promoDiscount} onChange={onChange} type="number" name="promoDiscount" size="small" id="promo-discout" label="%" variant="outlined" style={{ marginTop: "8px" }}

                                type="number" min="5" max="100"
                                    required
                                />

                                <p style={{fontSize: 11, textAlign: 'left', marginTop: 10}}>
Should be between 5-100. You do not need to include “%” in your answer
                                </p>
                                <h3 style={{ marginTop: "32px" }}>Select a voucher limit for your campaign</h3>
                                <TextField value={merchant.promoQty} onChange={onChange} type="number" name="promoQty" size="small" id="promo-qty" label="Qty" variant="outlined" style={{ marginTop: "8px" }}

                                type="number" min="5" max="100"
                                    required
                                />
                                <p style={{fontSize: 11, textAlign: 'left', marginTop: 10}}>
Enter the maximum number of campaign vouchers for this option to be sold per month

                                </p>

                                <Button 
                                    type="submit"
                                    variant="contained" 
                                    style={{ 
                                        width: '100%', 
                                        marginTop: "16px" , 
                                        backgroundColor: '#1190ad' , 
                                        color: '#fff' }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                        <Button 
                            variant="text" 
                            style={{ 
                                width: '100%', 
                                marginTop: "16px" , 
                                color: '#1190ad' , 
                            }}
                            onClick={showPrevStep}
                        >
                            Previous
                        </Button>



            </>)
            case 3:
                function uploadContinue(e) {
                    e.preventDefault();
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', `${process.env.REACT_APP_DOMAIN}/merchant/upload`, true)
                    xhr.withCredentials = true;

                    const form = e.target
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                            const nextStep = currentStep + 1
                            setCurrentStep(nextStep)
                            window.history.replaceState(null, null, `/`);
                        }
                        if (xhr.readyState == 4 && xhr.status == 500){
                            try {
                                const msg = JSON.parse(xhr.responseText);
                                setShowError(msg || "Something went wrong. Please try again");
                            } catch(err) {
                                setShowError("Unexpected Error");
                            }
                        }
                    }
                    xhr.addEventListener('error', (e) => {
                        setShowError("Unexpected Error");
                    });
                    xhr.addEventListener("load", () => {console.info("complete")});

                    const formdata = new FormData(e.target);
                    formdata.append('pid', merchant.promoId)
                    xhr.send(formdata);
                    
                }
                const onImageChange = (e) => {
                    const reader = new FileReader();
                    function handleEvent(event) {
                        const msg = `${event.type}: ${event.loaded} bytes transferred\n`;
                        console.info(msg)

                        if (event.type === "load") {
                            setPromoImage(reader.result)
                        }
                        else if (event.type === "loadstart") {
                            setLoading(true)
                        }
                        else if (event.type === "loadend") {
                            setLoading(false)
                        }
                    }
    
                    try {
                        const selectedImage = e.target.files[0];
                        if (selectedImage) {
                            reader.addEventListener('progress', handleEvent);
                            reader.addEventListener('load', handleEvent);
                            reader.addEventListener('error', handleEvent);
                            reader.addEventListener('loadstart', handleEvent);
                            reader.addEventListener('loadend', handleEvent);
                            reader.readAsDataURL(selectedImage);
                        }
                    } catch {
                        return
                    }
                }
                return (<>

                        {triggerPixel(step)}                    

                    <div style={{ width: '30vw', minWidth: '200px', marginTop: '32px' }}>
                        <div style={{ height: '30%', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center', }}>

                {showError
                    ?
                        <Alert severity="error">{showError}</Alert>
                    :
                    <></>
                }


                        <form style={{ width: '100%'}} onSubmit={showNextStep}> 
                            
                            <div style={{ marginTop: "8px", width: '100%', display: 'flex', flexDirection: "column", justifyContent: "center"}}>

                                <h3 style={{  textAlign: 'center' }}>Select a cover image for your deal</h3>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                      style={{
                                        position: 'relative',
                                        marginTop: '16px',
                                        height: '250px',
                                        cursor: 'pointer',
                                        width: '100%',
                                        }}
                                      onClick={() => {promoImageRef.current.click()}}
                                    >
                                        <img src={promoImage} 
                                               alt="" 
                                               style={{
                                                  width: '100%',
                                                  height: '100%',
                                                  display: 'block',
                                                  marginRight: 'auto',
                                                  marginLeft: 'auto',
                                                  objectFit: 'contain',
                                               }}
                                          />
                                    {!promoImage && 
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#e7f2f6', position: 'absolute', top: '8px', bottom: '8px', left: '8px', right: '8px'}}> 
                                            <img width='25' src={addImg} alt="select image" />
                                        </div>
                                    }

                                          <input
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            ref={promoImageRef}
                                            onChange={onImageChange}
                                            hidden
                                            required
                                          />
                                          {loading && (
                                          <CircularProgress
                                              size={24}
                                              style={{
                                                color: "#1190ad",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                              }}
                                            />
                                          )}
                                  </Box>
                                <p style={{ fontSize: '14px', textAlign: 'center' }}>
Give customers an idea of what they can expect with a photo of your business/product.
                                </p>
                                </Box>

                                <Button 
                                    type="submit"
                                    variant="contained" 
                                    disabled={loading}
                                    style={{ 
                                        width: '100%', 
                                        marginTop: "16px" , 
                                        backgroundColor: '#1190ad' , 
                                        color: '#fff' }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                        <Button 
                            variant="text" 
                            style={{ 
                                width: '100%', 
                                marginTop: "16px" , 
                                color: '#1190ad' , 
                            }}
                            onClick={showPrevStep}
                        >
                            Previous
                        </Button>


                    </div>
                  </div>
            </>)
            case 4:
                return (<>

                        {triggerPixel(step)}                    

                    <div style={{ width: '30vw', minWidth: '200px', marginTop: '32px' }}>
                        <div style={{ height: '30%', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center', }}>


                        <form style={{ width: '100%'}} onSubmit={submitSignupDetails}> 
                            
                            <div style={{ marginTop: "8px", width: '100%', display: 'flex', flexDirection: "column", justifyContent: "center"}}>

                                <h3 style={{ textAlign: 'center' }}>
                                    Select a time for your onboarding call
                                </h3>

                                <div style={{ margin: "16px 0px" }}>
                                <CalendlyCalendar />
                                </div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ m: 0, position: 'relative', width: '100%' }}>
                                        <Button 
                                            type="submit"
                                            variant="contained" 
                                            disabled={loading}
                                            style={{ 
                                                width: '100%', 
                                                marginTop: "16px" , 
                                                backgroundColor: '#1190ad' , 
                                                color: '#fff' }}
                                        >
                                            Go to my Dashboard
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                style={{
                                                  color: "#fff",
                                                  position: 'absolute',
                                                  top: '50%',
                                                  left: '50%',
                                                  marginTop: '-4px',
                                                  marginLeft: '-12px',
                                                }}
                                              />
                                            )}
                                    </Box>
                                </Box>

                            </div>
                        </form>
                        <Button 
                            variant="text" 
                            style={{ 
                                width: '100%', 
                                marginTop: "16px" , 
                                color: '#1190ad' , 
                            }}
                            onClick={showPrevStep}
                        >
                            Previous
                        </Button>


                    </div>
                  </div>
            </>)
            default:
                return (<></>)
        }
    }

    return (
        <>
            <div style={{ width: "100vw", height: "95vh", margin: '16px 0px' }}>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: "column", alignItems: 'center',  overflow: 'scroll' ,margin: "32px 0px"}}>
                    <div style={{ padding: '32px', justifyContent: 'center', alignItems: 'center' }}>
                        <KlippitIcon />
                    </div>
                    <div style={{ padding: '32px' }}>
                        <div style={{ width: '100%', minWidth: '200px', marginTop: '32px', paddingLeft: '32px', paddingRight: '32px' }}>
                          <div style={{ height: '30%', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
                            <h2 style={{ margin: "8px", fontFamily: "Arvo-Bold", color:"#202325", fontSize: "44px" }}>Step {currentStep + 1} out of 5</h2>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                                <div style={{ height: '30%', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center',padding: '64px 0px'}}>
                                    {renderSignupStep(currentStep)}
                                </div>
                                <div style={{ height: '30%', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center', padding: '64px 64px'}}>
                                    <iframe width="560" height="360" src={videoStepUrl()} title="steps" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignupDetails;
